import { useTranslation } from "~/i18n";
import { useSession } from "~/session";
import type { ComponentProps } from "solid-js";
import { Link } from "~/components/link.tsx";

export type Props = ComponentProps<"div"> & {
  privacyPolicyUrl?: string;
  legalNoticesUrl?: string;
  variant: "primary" | "secondary";
};

export const LegalBanner = (props: Props) => {
  const [session] = useSession();
  const { t } = useTranslation(session.locale);

  function openLegalBanner() {
    if (typeof window.Didomi === "undefined") return;
    window.Didomi.preferences.show();
  }

  return (
    <div class="inline-flex gap-3">
      <Link href={props.privacyPolicyUrl} variant={props.variant} size="sm" target="_blank">
        {t("legalBanner.privacy")}
      </Link>
      <Link href={props.legalNoticesUrl} variant={props.variant} size="sm" target="_blank">
        {t("legalBanner.legalNotices")}
      </Link>
      <Link variant={props.variant} onClick={openLegalBanner} size="sm" as="button">
        {t("legalBanner.management")}
      </Link>
    </div>
  );
};
